<template>
    <div class="tablet:flex gap-20">
        <div v-for="col in columns" :key="col[0].author">
            <ol class="">
                <li
                    v-for="item in col"
                    :key="item.quote"
                    class="rounded-20 p-20 tablet:p-30 mb-10 tablet:mb-20 last:mb-0 first:mt-10 tablet:first:mt-0"
                    :class="item.bg"
                >
                    <svg
                        viewBox="0 0 30 20"
                        xmlns="http://www.w3.org/2000/svg"
                        :class="item.quoteColor"
                        class="mb-10 w-30 tablet:mb-20 block"
                    >
                        <path
                            d="M5.825 20C9.333 20 12 17.63 12 14.667c0-2.445-1.404-3.408-2.807-4.148C8.14 9.926 6.877 9.48 6.877 8.296c0-1.777 1.895-3.63 3.088-4.592.772-.667 1.403-1.185 1.403-2.074 0-.963-.701-1.63-1.754-1.63C6.386 0 0 6.815 0 13.778 0 17.852 1.895 20 5.825 20zm18 0C27.333 20 30 17.63 30 14.667c0-2.445-1.404-3.408-2.807-4.148-1.053-.593-2.316-1.038-2.316-2.223 0-1.777 1.895-3.63 3.088-4.592.772-.667 1.403-1.185 1.403-2.074 0-.963-.701-1.63-1.754-1.63C24.386 0 18 6.815 18 13.778 18 17.852 19.895 20 23.825 20z"
                            fill="currentColor"
                            fill-rule="evenodd"
                        />
                    </svg>
                    <p
                        class="text-14/20 tablet:text-16/24"
                        v-html="item.quote"
                    ></p>
                    <div
                        class="mt-10 tablet:mt-20 font-semibold text-16/22 tablet:text-18/26 desktop:text-18/28"
                    >
                        {{ item.author }}
                    </div>
                </li>
            </ol>
        </div>
    </div>
</template>

<script setup lang="ts">
const items = [
    {
        author: 'Jose G.',
        quote: `I was expecting something good, but the results went above and beyond! Way more thorough and detailed than I had expected, and they were also very accurate!`,
        bg: 'bg-green/10',
        quoteColor: 'text-green',
    },
    {
        author: 'Ivy T.',
        quote: 'Gosh. What a masterpiece.',
        bg: 'bg-yellow/10',
        quoteColor: 'text-yellow',
    },
    {
        author: 'Yitty B.',
        quote: 'I am so happy that I took the time to do this test. I am enjoying every line that I read, because I feel and act almost exactly the way that my results say I do! A question I am now stuck with is, <em>‘How?!’</em> How on earth did these results come from asking me a few questions?! I am honestly, utterly flabbergasted! In a good way!',
        bg: 'bg-teal/10',
        quoteColor: 'text-teal',
    },
    {
        author: 'SG',
        quote: `All in all, I’d say this is a really good [test]… Kudos to the team for creating this.`,
        bg: 'bg-brown/10',
        quoteColor: 'text-brown',
    },
    {
        author: 'Neptune G.',
        quote: `This was a really great experience! It was mostly accurate, and there were some things that I knew about myself but never found the proper words to describe them. The description at the end really nailed this one thing I was wondering about myself for years.`,
        bg: 'bg-orange/10',
        quoteColor: 'text-orange',
    },
    {
        author: 'Hasan A.',
        quote: 'I was honestly a bit skeptical when I saw that it was in a story format, as I somewhat doubted its accuracy, but quite to the contrary, I’ve found it to be an amazing take on a test by being a perfect mix of fun and reality whilst also producing results with a ludicrous level of depth and accuracy.',
        bg: 'bg-green/10',
        quoteColor: 'text-green',
    },
    {
        author: 'Linde K.',
        quote: 'The results were very introspective and aligned with my own self-image. My down-to-earth view of art was captured, haha!',
        bg: 'bg-yellow/10',
        quoteColor: 'text-yellow',
    },
] as const

const columns = [items.slice(0, 4), items.slice(4, 7)]
</script>

<template>
    <div
        class="relative mx-auto mt-20 tablet:mt-10 pb-40 tablet:pb-60 desktop:pb-80"
    >
        <div class="w-screen max-w-full h-full absolute overflow-hidden">
            <img
                class="hidden desktop:block absolute top-[calc(50%-95px)] left-[calc(50%-1280px)] max-w-none"
                src="~/assets/images/story-background-line-desktop.svg"
                aria-hidden
            />
            <img
                class="hidden tablet:block desktop:hidden absolute left-[calc(50%-600px)] max-w-none"
                src="~/assets/images/story-background-line-tablet.svg"
                aria-hidden
            />
            <img
                class="tablet:hidden absolute top-0 left-[calc(50%-384px)] max-w-none"
                src="~/assets/images/story-background-line-phone.svg"
                aria-hidden
            />
        </div>
        <div
            class="desktop:max-w-container tablet:max-w-[768px] max-w-[360px] mx-auto px-15 tablet:px-20"
        >
            <ol
                class="flex flex-col tablet:flex-row flex-wrap gap-40 tablet:gap-0 desktop:justify-between desktop:px-30"
            >
                <li
                    v-for="item in items"
                    :key="item.text"
                    class="dekstop:contents"
                    :class="item.container"
                >
                    <div
                        class="relative group size-[270px] tablet:size-[290px] desktop:size-[250px] flex flex-col justify-center"
                        :class="[item.position]"
                    >
                        <span
                            aria-hidden
                            :class="[item.bgColor]"
                            class="absolute size-[270px] tablet:size-[290px] desktop:size-[250px] desktop:group-hover:scale-[1.08] transition-all rounded-full"
                        ></span>
                        <span
                            v-for="circle in item.satelliteCircles"
                            aria-hidden
                            :class="[
                                circle.position,
                                circle.size,
                                item.satelliteCirclesColor,
                            ]"
                            class="absolute rounded-full transition-all duration-500 desktop:opacity-0 desktop:group-hover:opacity-100"
                        ></span>
                        <div
                            class="opacity-100 desktop:group-hover:opacity-0 transition-opacity duration-300 text-center desktop:absolute z-10"
                        >
                            <h5
                                class="text-h4 tablet:text-h4-tablet desktop:text-32/40 desktop:-tracking-150 mb-10 font-title px-30 desktop:mt-10"
                            >
                                {{ item.title }}
                            </h5>
                            <p class="hidden desktop:block text-16/24">
                                See more
                            </p>
                        </div>
                        <div
                            class="desktop:opacity-0 desktop:group-hover:opacity-100 transition-opacity duration-300 text-center desktop:absolute z-10"
                        >
                            <p
                                class="text-14/20 tablet:text-16/24 desktop:text-18/28 px-30 tablet:px-40 desktop:px-30"
                            >
                                {{ item.text }}
                            </p>
                        </div>
                    </div>
                </li>
            </ol>
        </div>
    </div>
</template>

<script setup lang="ts">
const items = [
    {
        title: 'A regular workday',
        text: 'Outside consultants are at the office. Do you ignore the speculation or lead the charge?',
        bgColor: 'bg-teal',
        position: 'mt-20 ttablet:mr-[84px] tablet:mt-30 desktop:m-0',
        container: '',
        satelliteCirclesColor: 'bg-teal/30',
        satelliteCircles: [
            {
                position: [
                    'top-0 left-0 tablet:top-10 tablet:left-10',
                    'desktop:left-0 desktop:top-0',
                    'desktop:translate-y-20 desktop:translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-30',
            },
            {
                position: [
                    'right-20 top-10 tablet:top-20 tablet:right-30',
                    'desktop:right-10 desktop:top-10',
                    'desktop:translate-y-20 desktop:-translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-50',
            },
            {
                position: [
                    'bottom-0 left-0 tablet:bottom-10 tablet:left-10',
                    'desktop:-left-0 desktop:-bottom-10',
                    'desktop:-translate-y-20 desktop:translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-[90px]',
            },
            {
                position: [
                    'right-0 bottom-20 tablet:bottom-30 tablet:right-10',
                    'desktop:-right-0 desktop:bottom-10',
                    'desktop:-translate-y-20 desktop:-translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-30',
            },
        ],
    },
    {
        title: 'Rooftop party',
        text: 'You meet up with some coworkers after hours. Do you keep it at that or start a new friendship?',
        bgColor: 'bg-brown',
        position: 'ml-auto tablet:mt-[130px] tablet:mx-auto desktop:m-0',
        container: 'tablet:grow desktop:grow-0',
        satelliteCirclesColor: 'bg-brown/30',
        satelliteCircles: [
            {
                position: [
                    'top-20 left-0 tablet:top-30 tablet:left-10',
                    'desktop:-left-0 desktop:top-10',
                    'desktop:translate-y-20 desktop:translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-30',
            },
            {
                position: [
                    'top-0 right-0 tablet:top-10 tablet:right-10',
                    'desktop:-right-10 desktop:top-0',
                    'desktop:translate-y-20 desktop:-translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-[90px]',
            },
            {
                position: [
                    'left-20 bottom-10 tablet:bottom-20 tablet:left-30',
                    'desktop:left-20 desktop:-bottom-0',
                    'desktop:-translate-y-20 desktop:translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-50',
            },
            {
                position: [
                    'bottom-0 right-0 tablet:bottom-10 tablet:right-10',
                    'desktop:-right-10 desktop:bottom-0',
                    'desktop:-translate-y-20 desktop:-translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-30',
            },
        ],
    },
    {
        title: 'To stay or go',
        text: 'Decision time: Do you go on a spontaneous trip to the big city or stay home?',
        bgColor: 'bg-green',
        position: 'tablet:mx-auto desktop:m-0',
        container: 'tablet:grow desktop:grow-0',
        satelliteCirclesColor: 'bg-green/30',
        satelliteCircles: [
            {
                position: [
                    'top-10 left-20 tablet:top-20 tablet:left-30',
                    'desktop:left-10 desktop:top-10',
                    'desktop:translate-y-20 desktop:translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-50',
            },
            {
                position: [
                    'top-0 right-0 tablet:top-10 tablet:right-10',
                    'desktop:right-0 desktop:top-0',
                    'desktop:translate-y-20 desktop:-translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-30',
            },
            {
                position: [
                    'bottom-20 left-0 tablet:bottom-30 tablet:left-10',
                    'desktop:left-0 desktop:bottom-10',
                    'desktop:-translate-y-20 desktop:translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-30',
            },
            {
                position: [
                    'right-0 bottom-0 tablet:bottom-10 tablet:right-10',
                    'desktop:right-0 desktop:-bottom-10',
                    'desktop:-translate-y-20 desktop:-translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-[90px]',
            },
        ],
    },
    {
        title: 'High school reunion',
        text: 'Will you connect with a few close friends or interact with as many people as possible?',
        bgColor: 'bg-orange',
        position: 'ml-auto tablet:mt-100 tablet:ml-[84px] desktop:m-0',
        container: '',
        satelliteCirclesColor: 'bg-orange/30',
        satelliteCircles: [
            {
                position: [
                    'top-0 left-0 tablet:top-10 tablet:left-10',
                    'desktop:left-0 desktop:top-0',
                    'desktop:translate-y-20 desktop:translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-[90px]',
            },
            {
                position: [
                    'top-20 right-0 tablet:top-30 tablet:right-10',
                    'desktop:right-0 desktop:top-10',
                    'desktop:translate-y-20 desktop:-translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-30',
            },
            {
                position: [
                    'bottom-0 left-0 tablet:bottom-10 tablet:left-10',
                    'desktop:left-0 desktop:-bottom-10',
                    'desktop:-translate-y-20 desktop:translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-30',
            },
            {
                position: [
                    'bottom-10 right-20 tablet:bottom-20 tablet:right-30',
                    'desktop:right-10 desktop:bottom-10',
                    'desktop:-translate-y-20 desktop:-translate-x-20',
                    'desktop:group-hover:translate-y-0 desktop:group-hover:translate-x-0',
                ],
                size: 'size-50',
            },
        ],
    },
] as const
</script>

<template>
    <ol
        class="flex flex-col tablet:grid tablet:grid-cols-2 desktop:grid-cols-4 gap-20 tablet:gap-y-40 dekstop:gap-y-[47px]"
    >
        <li v-for="item in items" class="dekstop:contents">
            <div class="flex tablet:block text-14/20 tablet:text-16/24">
                <div
                    class="shrink-0 size-40 tablet:size-50 flex flex-col items-center justify-center rounded-full mr-10 tablet:mr-0 tablet:mb-10"
                    :class="[item.iconBg]"
                >
                    <img
                        :src="item.icon"
                        :alt="item.title"
                        class="size-[18px] tablet:size-20"
                        :class="item.iconColor"
                    />
                </div>
                <div>
                    <h4 class="font-bold inline">{{ item.title }}</h4>
                    <p class="inline">{{ item.text }}</p>
                </div>
            </div>
        </li>
    </ol>
</template>

<script setup lang="ts">
const items = [
    {
        icon: (await import('~/assets/images/benefit-1-icon.svg')).default,
        iconBg: 'bg-green/10',
        iconColor: 'text-green',
        title: 'Discover Yourself. ',
        text: 'Uncover hidden layers deep within for profound self-awareness.',
    },
    {
        icon: (await import('~/assets/images/benefit-2-icon.svg')).default,
        iconBg: 'bg-yellow/10',
        iconColor: 'text-yellow',
        title: 'Empower Decisions. ',
        text: 'Make confident life choices guided by personality-driven insights.',
    },
    {
        icon: (await import('~/assets/images/benefit-3-icon.svg')).default,
        iconBg: 'bg-orange/10',
        iconColor: 'text-orange',
        title: 'Shape Storylines. ',
        text: 'Your choices steer your narrative, putting you in the driver’s seat.',
    },
    {
        icon: (await import('~/assets/images/benefit-4-icon.svg')).default,
        iconBg: 'bg-teal/10',
        iconColor: 'text-teal',
        title: 'Unlock Potential. ',
        text: 'Leverage guidance to optimize strengths and manage weaknesses.',
    },
    {
        icon: (await import('~/assets/images/benefit-5-icon.svg')).default,
        iconBg: 'bg-brown/10',
        iconColor: 'text-brown',
        title: 'Improve Relationships. ',
        text: 'Use this as a conversation starter toward mutual understanding.',
    },
    {
        icon: (await import('~/assets/images/benefit-6-icon.svg')).default,
        iconBg: 'bg-green/10',
        iconColor: 'text-green',
        title: 'Foster Growth. ',
        text: 'Build both understanding and empathy through greater self-awareness.',
    },
    {
        icon: (await import('~/assets/images/benefit-7-icon.svg')).default,
        iconBg: 'bg-yellow/10',
        iconColor: 'text-yellow',
        title: 'Boost Intelligence. ',
        text: 'Enhance your emotional intelligence through deep self-reflection.',
    },
    {
        icon: (await import('~/assets/images/benefit-8-icon.svg')).default,
        iconBg: 'bg-orange/10',
        iconColor: 'text-orange',
        title: 'Increase Confidence. ',
        text: 'Knowledge creates deeper self-recognition and powerful self-belief.',
    },
] as const
</script>
